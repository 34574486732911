<div>
  <div [className]="row" class="accessibility-link-group" *ngIf="!contact || isDynamicContact(contact)">
    <div [className]="columns">
      <img style="width: 21px; height: 21px;" src="assets/images/Contact_Icon.svg"/>
      <span class="title">Name</span>
      <br>
      <span *ngIf="hasContactName(contact)" class="data">{{ contact.contactName }}</span>
      <span *ngIf="!hasContactName(contact) || !contact" class="data">{{ CONSTANTS.unassigned }}</span>
    </div>
    <br>
    <div [className]="columns">
      <img style="width: 21px; height: 21px;" src="assets/images/Phone_Icon.svg"/>
      <span class="title">Call</span>
      <br>
      <span *ngIf="hasContactPhone(contact)" class="data"><a [href]="'tel:' + contact.ph">{{ contact.ph }}</a></span>
      <span *ngIf="!hasContactPhone(contact) || !contact" class="data">{{ CONSTANTS.unassigned }}</span>
    </div>
    <br>
    <div [className]="columns">
      <img style="width: 21px; height: 21px;" src="assets/images/Email_Icon.svg"/>
      <span class="title">Email</span>
      <br>
      <span *ngIf="hasContactEmail(contact)" class="data"><a [href]="'mailto:' + contact.email">{{ contact.email }}</a></span>
      <span *ngIf="!hasContactEmail(contact) || !contact" class="data">{{ CONSTANTS.unassigned }}</span>
    </div>
  </div>

  <div [className]="row" *ngIf="contact && !isDynamicContact(contact)">
    <div [className]="columns">
      <img style="width: 21px; height: 21px;" src="assets/images/Phone_Icon.svg"/>
      <span class="title">Call</span>
      <br>
      <span class="data" *ngFor="let phone of contact.phoneNumbers"> {{ phone.phoneNumber }} </span>
      <span class="data" *ngFor="let time of contact.operationalHours"> {{ time.opnHrs }} </span>
    </div>
    <div [className]="columns" *ngIf="contact.email">
      <span class="allycon-mail-sealed"></span>
      <span class="title"> Email </span>
      <br>
      <span class="data"> {{ contact.email }} </span>
    </div>
    <div [className]="columns">
      <span class="allycon-mail-sealed"></span>
      <div *ngFor="let address of contact.addresses">
        <span class="title" *ngIf="address.addressTitle"> {{ address.addressTitle }}</span>
        <br>
        <span class="data" *ngIf="address.subTitle"> {{ address.subTitle }} </span>
        <br>
        <span class="data" *ngIf="address.address1"> {{ address.address1 }} </span>
        <br>
        <span class="data" *ngIf="address.address2"> {{ address.address2 }} </span>
        <br>
        <span class="data"> {{ address.city }} &nbsp; {{ address.state }} &nbsp; {{ address.zipCode }} </span>
      </div>
    </div>
  </div>
</div>