import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { initialize, LDClient, LDFlagSet} from 'launchdarkly-js-client-sdk';
import { environment } from '../../../environments/environment';
import { SessionManagementService } from '../session-management.service';
import { StoreService } from '../store.service';

@Injectable()
export class LaunchDarklyService {
  ldClient: LDClient;
  flags: LDFlagSet;
  flagChange: Subject<Object> = new Subject<Object>();
  userContext: {};
  preLoginContext: {};
  userKey: string;

  _subscription: any;
  allLDFlags: Object;
  showDM: boolean;
  showADR: boolean;
  showDashMSG: any;
  useSCAlternateUrl: boolean;

  constructor(
    private sessionManager: SessionManagementService,
    private storeService: StoreService,
  ) {
    /* 
      Add newly created flags from DASH LD Project here to initialize them and set a default value
      { string: any } Must match LD flag key
    */
    this.flags = {
      'document-manager': false, // Boolean
      'dash_app_adr': false, // Boolean
      'dash_app_sc': false, // Boolean
      'dash-message': {} // JSON Object
    }

    // Sets the user key to whatever environment we are in
    this.userKey = 'key-user-' + environment.env;

    //Initializes the context before the user has logged in
    this.preLoginContext= {
      key: this.userKey,
      kind: 'user'
    };

    // Uses Environment Specific Client ID and user context to initialize connection to LaunchDarkly 
    this.ldClient = initialize(environment.launchDarkly.clientSideId, this.preLoginContext);
    
    // If properly connected and initialized
    this.ldClient.on('initialized', () => {
      console.log("LaunchDarkly flags sucessfully initialized.");
    });
     
    // If failed to properly connect and initialize
    this.ldClient.on('failed', () => {
      console.log("LaunchDarkly flags failed to initialize.");
    });

    /* 
      Will run to gather flag data regardless of success or failure
      Will loop through all flags that were set up above in this.flags
    */
    this.ldClient.on('ready', (flags) => {
      Object.entries(this.flags).forEach(([key, value]: [string, any]) => {
        this.ldClient.variation(key, value);
        this.flags[key] = flags[key];
      })
    });
 
    // Tracks changes made to flags
    this.ldClient.on('change', (flags) => {
      Object.entries(this.flags).forEach(([key, value]: [string, any]) => {      
      if(flags[key] !== undefined) {
        this.flags[key] = flags[key];
      }
      this.flagChange.next(this.flags);
      })
      console.log("LaunchDarkly flags updated.");
    })
  }

  // Fetches LD flags, keeps track of changes, and stores them in session service
  storeLDFlagsInSession(): void {
   this._subscription = this.flagChange.subscribe((flags) => {
    this.allLDFlags = flags;
    this.showDM = flags['document-manager'].current;
    this.showADR = flags['dash_app_adr'].current;
    this.showDashMSG = flags['dash-message'].current;
    this.useSCAlternateUrl = flags['dash_app_sc'].current;
    this.storeService.write('allLaunchDarklyFlags', this.allLDFlags);
    this.storeService.write('launchDarklyDMFlag', this.showDM);
    this.storeService.write('launchDarklyADRFlag', this.showADR);
    this.storeService.write('launchDarklyDashMSGFlag', this.showDashMSG);
    this.storeService.write('launchDarklySCFlag', this.useSCAlternateUrl);
  });
  }


  // Updates Context in LD to user context once the login has been successful
  updateContext(): void {
    //dealer user
    if(this.sessionManager.profile.userType ==='D'){
      this.userContext = {
        kind: 'user',
        key: this.sessionManager.profile.userId,
        firstName: this.sessionManager.profile.firstName,
        lastName: this.sessionManager.profile.lastName,
        email: this.sessionManager.profile.email,
        username: this.sessionManager.profile.userName,
        pdn: this.sessionManager.profile.dealership.pdn,
        environment: environment.env,
      };
    }
    //Ally user
    else{
      this.userContext = {
        kind: 'user',
        key: this.sessionManager.profile.userId,
        firstName: this.sessionManager.profile.firstName,
        lastName: this.sessionManager.profile.lastName,
        email: this.sessionManager.profile.email,
        username: this.sessionManager.profile.userName,
        environment: environment.env,
      };
    }
    this.ldClient.identify(this.userContext);
  }
}